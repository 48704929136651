.advancedFacetViewWrapper {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
}

.advancedFacetViewWrapper .loadingScreen {
  background: rgba(255, 255, 255, 0.9);
}

.facetViewWrapper {
  display: flex;
  flex-direction: row;
}

.facetViewWrapper .panel {
  position: relative;
}

.facetViewWrapper .treeViewPanel {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.facetViewWrapper .treeViewPanel .panelHeading {
  display: flex;
  justify-content: space-between;
}

.facetViewWrapper .treeViewPanel {
  overflow-y: scroll;
  flex: 1;
  margin-right: 5px;
  margin-top: 10px;
}

.facetViewWrapper .facetsPanel {
  flex: 2;
  margin-left: 5px;
  margin-top: 10px;
}

.facetViewWrapper .facetsPanel .facetView {
  height: 100%;
  overflow-y: scroll;
}

.facetViewWrapper .facetsPanel .facets {
  position: absolute;
  top: 50px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: hidden;
}

.facetViewWrapper
  .facetsPanel
  .facets
  .react-datepicker-popper[data-placement='bottom-end']
  .react-datepicker__triangle {
  left: 200px !important;
}

.facetViewWrapper .facetsPanel .facetView .facetViewNode {
  padding-left: 20px;
}

.facetViewWrapper .facetsPanel .panelHeading {
  position: absolute;
  left: 0px;
  right: 0px;
  height: 50px;
}

.facetViewWrapper {
  font-size: 13px;
  height: 100%;
}

.facetViewWrapper .bucket-count {
  display: inline-block;
}

.facetViewWrapper .bucket-link {
  word-wrap: break-word;
}

.facetViewWrapper .bucket .bucket-item {
  display: block;
  padding: 5px;
  min-width: 25%;
  max-width: 25%;
}

.facetViewWrapper .bucket {
  display: flex;
  flex-wrap: wrap;
}

.facetViewWrapper .bucket .bucket-item.only_row {
  border-bottom: none;
}

.facetViewWrapper .facetContainer {
  margin-top: 10px;
}
.facetViewWrapper .facetContainer .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: bold;
  padding: 5px 20px;
}

.facetViewWrapper .facetContainer .header .breadscrumbs {
  text-align: right;
}
.facetViewWrapper
  .facetContainer
  .header
  .breadscrumbs
  .breadscrumb-item:not(:last-child):after {
  content: ' » ';
}
.facetViewWrapper .facetContainer .content {
  padding: 10px;
}
