.aggregation-card .title-wrapper,
.aggregation-card .filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.aggregation-card .header {
  background: white;
}
.aggregation-card .arrow:after {
  content: '›';
}
.aggregation-card .arrow.collapsed {
  transform: rotate(0deg);
}
.aggregation-card .arrow {
  transform: rotate(90deg);
  transition: all 0.5s;
  cursor: pointer;
}
.aggregation-card .action-icon {
  cursor: pointer;
}
.aggregation-card .showMore-wrapper {
  display: flex;
  justify-content: flex-end;
}
